<template>
  <el-container>
    <el-aside width="200px">
      <vm-tree type="useUnit/false" @node-click="onNodeClick"></vm-tree>
    </el-aside>
    <el-aside style="padding:0;margin-left: 10px" width="820px">
      <div> {{$t("monitor.currentUseUnit")}}：{{useUnitName}}</div>
      <div
        v-if="buildNum > 0"
        style="margin-top: 10px"
        class="bgimg"
        :class="{ 'bgimg1' :isbgimg1,'bgimg2': isbgimg2, 'bgimg3': isbgimg3, 'bgimg4': isbgimg4, 'bgimg5': isbgimg5, 'bgimg6': isbgimg6, 'bgimg7': isbgimg7, 'bgimg8': isbgimg8, 'bgimg9': isbgimg9, 'bgimg10': isbgimg10 }">
        <div v-if="buildNum > 10 && currePage > 1" class="last" @click="findLast()">last</div>
        <div v-if="buildNum > 10 && currePage < totalPage" class="next" @click="findNext()">next</div>
        <div class="colorinfo">
          <ul>
            <li><img src="/static/images/useUnitImages/blue.jpg" alt=""/> {{$t("monitor.fault.fault1")}}</li>
            <li><img src="/static/images/useUnitImages/grey.jpg" alt=""/> {{$t("monitor.fault.fault3")}}</li>
            <li><img src="/static/images/useUnitImages/red.jpg" alt=""/> {{$t("monitor.fault.fault2")}}</li>
          </ul>
        </div>
        <div v-if="buildCurrentNum > 0" class="bd1">
          <div v-if="buildList[0].state === 1 " class="num">{{buildList[0].buildingNo}}</div>
          <div v-if="buildList[0].state === 2 " class="num grey">{{buildList[0].buildingNo}}</div>
          <div v-if="buildList[0].state === 3 " class="num red">{{buildList[0].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/1_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/1_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/1_out.png'"
            alt=""
            @click="clickImage(buildList[0].buildingNo,buildList[0].useUnitId)"/>
        </div>
        <div v-if="buildCurrentNum > 1" class="bd2">
          <div v-if="buildList[1].state === 1 " class="num">{{buildList[1].buildingNo}}</div>
          <div v-if="buildList[1].state === 2 " class="num grey">{{buildList[1].buildingNo}}</div>
          <div v-if="buildList[1].state === 3 " class="num red">{{buildList[1].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/2_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/2_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/2_out.png'"
            alt=""
            @click="clickImage(buildList[1].buildingNo,buildList[1].useUnitId)"/>
        </div>
        <div v-if="buildCurrentNum > 2" class="bd3">
          <div v-if="buildList[2].state === 1 " class="num">{{buildList[2].buildingNo}}</div>
          <div v-if="buildList[2].state === 2 " class="num grey">{{buildList[2].buildingNo}}</div>
          <div v-if="buildList[2].state === 3 " class="num red">{{buildList[2].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/3_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/3_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/3_out.png'"
            alt=""
            @click="clickImage(buildList[2].buildingNo,buildList[2].useUnitId)"/>
        </div>
        <div v-if="buildCurrentNum > 3" class="bd4">
          <div v-if="buildList[3].state === 1 " class="num">{{buildList[3].buildingNo}}</div>
          <div v-if="buildList[3].state === 2 " class="num grey">{{buildList[3].buildingNo}}</div>
          <div v-if="buildList[3].state === 3 " class="num red">{{buildList[3].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/4_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/4_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/4_out.png'"
            alt=""
            @click="clickImage(buildList[3].buildingNo,buildList[3].useUnitId)"/>
        </div>
        <div v-if="buildCurrentNum > 4" class="bd5">
          <div v-if="buildList[4].state === 1 " class="num">{{buildList[4].buildingNo}}</div>
          <div v-if="buildList[4].state === 2 " class="num grey">{{buildList[4].buildingNo}}</div>
          <div v-if="buildList[4].state === 3 " class="num red">{{buildList[4].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/5_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/5_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/5_out.png'"
            alt=""
            @click="clickImage(buildList[4].buildingNo,buildList[4].useUnitId)"/>
        </div>
        <div v-if="buildCurrentNum > 5" class="bd6">
          <div v-if="buildList[5].state === 1 " class="num">{{buildList[5].buildingNo}}</div>
          <div v-if="buildList[5].state === 2 " class="num grey">{{buildList[5].buildingNo}}</div>
          <div v-if="buildList[5].state === 3 " class="num red">{{buildList[5].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/6_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/6_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/6_out.png'"
            alt=""
            @click="clickImage(buildList[5].buildingNo,buildList[5].useUnitId)"/>
        </div>
        <div v-if="buildCurrentNum > 6" class="bd7">
          <div v-if="buildList[6].state === 1 " class="num">{{buildList[6].buildingNo}}</div>
          <div v-if="buildList[6].state === 2 " class="num grey">{{buildList[6].buildingNo}}</div>
          <div v-if="buildList[6].state === 3 " class="num red">{{buildList[6].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/7_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/7_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/7_out.png'"
            alt=""
            @click="clickImage(buildList[6].buildingNo,buildList[6].useUnitId)"/>
        </div>
        <div v-if="buildCurrentNum > 7" class="bd8">
          <div v-if="buildList[7].state === 1 " class="num">{{buildList[7].buildingNo}}</div>
          <div v-if="buildList[7].state === 2 " class="num grey">{{buildList[7].buildingNo}}</div>
          <div v-if="buildList[7].state === 3 " class="num red">{{buildList[7].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/8_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/8_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/8_out.png'"
            alt=""
            @click="clickImage(buildList[7].buildingNo,buildList[7].useUnitId)"/>
        </div>
        <div v-if="buildCurrentNum > 8" class="bd9">
          <div v-if="buildList[8].state === 1 " class="num">{{buildList[8].buildingNo}}</div>
          <div v-if="buildList[8].state === 2 " class="num grey">{{buildList[8].buildingNo}}</div>
          <div v-if="buildList[8].state === 3 " class="num red">{{buildList[8].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/9_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/9_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/9_out.png'"
            alt=""
            @click="clickImage(buildList[8].buildingNo,buildList[8].useUnitId)"/>
        </div>
        <div v-if="buildCurrentNum > 9" class="bd10">
          <div v-if="buildList[9].state === 1 " class="num">{{buildList[9].buildingNo}}</div>
          <div v-if="buildList[9].state === 2 " class="num grey">{{buildList[9].buildingNo}}</div>
          <div v-if="buildList[9].state === 3 " class="num red">{{buildList[9].buildingNo}}</div>
          <img
            src="/static/images/useUnitImages/10_out.png"
            class="pointer"
            onmouseover="this.src='/static/images/useUnitImages/10_on.png'"
            onmouseout="this.src='/static/images/useUnitImages/10_out.png'"
            alt=""
            @click="clickImage(buildList[9].buildingNo,buildList[9].useUnitId)"/>
        </div>
      </div>
    </el-aside>
  </el-container>
</template>
<script>
  import VmTree from "../../components/VmTree.vue";

  export default {
    components: {VmTree},
    data() {
      return {
        buildNum: 0,
        buildCurrentNum: 0,
        buildList: [],
        buildListTemp: [],
        isbgimg1: false,
        isbgimg2: false,
        isbgimg3: false,
        isbgimg4: false,
        isbgimg5: false,
        isbgimg6: false,
        isbgimg7: false,
        isbgimg8: false,
        isbgimg9: false,
        isbgimg10: false,
        currePage: 0,
        useUnitName: "",
      };
    },
    mounted() {
    },
    methods: {
      onNodeClick(data) {
        this.useUnitName = data.name;
        let params = {
          "useUnitId": data.id,
        };
        this.$api.getList("/useUnitMonitor", params).then(res => {
          let data = res.data;
          if (data.length >= 10) {
            this.buildCurrentNum = 10;
          } else {
            this.buildCurrentNum = data.length;
          }
          this.buildNum = data.length;
          this.currePage = 1;
          this.buildList = [].concat(JSON.parse(JSON.stringify(data)));
          this.buildListTemp = [].concat(JSON.parse(JSON.stringify(data)));
          this.totalPage = Math.ceil(this.buildNum / 10);
          this.setBack();
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      findLast() {
        this.currePage = this.currePage - 1;
        for (var i = 0; i < 10; i++) {
          let index = (this.currePage - 1) * 10 + i;
          this.buildList[i].buildingNo = this.buildListTemp[index].buildingNo;
        }
        this.buildCurrentNum = 10;
        this.setBack();
      },
      findNext() {
        this.currePage = this.currePage + 1;
        let buildCount = 0;

        for (var i = 0; i < 10; i++) {
          let length = this.buildListTemp.length;
          let index = (this.currePage - 1) * 10 + i;
          if (index < length) {
            this.buildList[i].buildingNo = this.buildListTemp[index].buildingNo;
            buildCount = i;
          } else {
            this.buildList[i].buildingNo = "";
          }
        }
        this.buildCurrentNum = buildCount + 1;
        this.setBack();
      },
      setBack(){
        if (this.buildCurrentNum === 1) {
          this.isbgimg1 = true;
        } else if (this.buildCurrentNum === 2) {
          this.isbgimg1 = false;
          this.isbgimg2 = true;
        } else if (this.buildCurrentNum === 3) {
          this.isbgimg2 = false;
          this.isbgimg3 = true;
        } else if (this.buildCurrentNum === 4) {
          this.isbgimg3 = false;
          this.isbgimg4 = true;
        } else if (this.buildCurrentNum === 5) {
          this.isbgimg4 = false;
          this.isbgimg5 = true;
        } else if (this.buildCurrentNum === 6) {
          this.isbgimg5 = false;
          this.isbgimg6 = true;
        } else if (this.buildCurrentNum === 7) {
          this.isbgimg6 = false;
          this.isbgimg7 = true;
        } else if (this.buildCurrentNum === 8) {
          this.isbgimg7 = false;
          this.isbgimg8 = true;
        } else if (this.buildCurrentNum === 9) {
          this.isbgimg8 = false;
          this.isbgimg9 = true;
        } else {
          this.isbgimg9 = false;
          this.isbgimg10 = true;
        }
      },
      clickImage(buildingNum, useUnitId) {
        window.open("#/monitorMore/" + useUnitId + "," + buildingNum + "," + this.useUnitName);
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "../../style/bgstyle.css";

.pointer {
  cursor: pointer;
}
</style>
